@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
    @apply text-4xl font-black
}

h2 {
    @apply text-3xl font-extrabold
}

h3 {
    @apply text-2xl font-bold
}

h4 {
    @apply text-xl font-semibold
}

h5 {
    @apply text-lg font-semibold
}

h6 {
    @apply text-lg font-medium
}